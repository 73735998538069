<template>
  <div class="container mx-auto px-3 max-w-screen-lg">
    <LoadingScreen v-if="isLoading" />

    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
      <div v-if="!isLoading">
        <div class="bg-white shadow-md px-20 py-6 rounded-lg mt-8 sm:w-full md:w-3/4 lg:w-2/3 m-auto">
          <h1 
            class="block text-xl text-secondary text-center font-semibold pt-3 pb-6 pr-2 md:pr-4 lg:pr-8"
            style="line-height: 1.2"
          >
            Подтверждение почты
          </h1>
          <div v-if="status === 'done'">
            Ваша почта успешно подтверждена!<br>
            Теперь Вы можете перейти к изучению видео роликов, прохождению 
            тестов или в свой личный кабинет.
          </div>

          <div v-if="status === 'error'">
            <b>Ошибка!</b><br><br>
            Ссылка подтверждения почты не верная или ваша почта уже подтверждена.
            Проверить подтверждение почты можно в своем личном кабинете.
          </div>
        </div>
      </div>
    </fade-transition>
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import { FadeTransition } from 'vue2-transitions'
import { mapActions } from 'vuex'

export default {
  name: 'EmailConfirmation',
  components: { LoadingScreen, FadeTransition },
  props: { token: String },
  data: function() {
    return {
      isLoading: true,
      status: null
    }
  },
  methods: {
    ...mapActions({ confirmEmail: 'user/confirmEmail' })
  },
  created() {
    this.confirmEmail(this.token)
      .then(() => {
        this.status = 'done'
      })
      .catch((error) => {
        this.status = 'error'
      })
      .finally(() => {
        this.isLoading = false
      })
  } 
}
</script>
